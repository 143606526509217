<template>
  <v-card
    @click="edit"
    flat
    outlined
    class="cart-info-card h-100 px-6 py-3 px-sm-3 py-sm-3"
  >
    <div class="d-flex justify-space-between align-center h-100">
      <div class="d-flex flex-row align-center">
        <v-icon v-if="icon" color="default" class="icon-info-card mr-2">{{
          icon
        }}</v-icon>
        <div v-else-if="imgSrc" class="img-container white pa-1">
          <v-img
            :src="imgSrc"
            contain
            class="img-info-card"
            alt="Immagine servizio"
            height="auto"
            width="24px"
          ></v-img>
        </div>
        <div class="text-body-2">
          <div class="text">{{ text }}</div>
          <div class="value font-weight-bold">{{ infoValue }}</div>
        </div>
      </div>
      <v-icon class="edit" color="default" right v-if="editable">
        $edit
      </v-icon>
    </div>
  </v-card>
</template>
<style lang="scss">
.cart-info-card {
  border: 1px solid $border-color;
  background-color: transparent;
  .img-container {
    margin-right: 8px;
    .v-image__image--contain {
      background-size: cover;
    }
  }
  @mixin hide-img-icon {
    .icon-info-card,
    .img-info-card {
      display: none;
    }
    .img-container {
      display: none;
      margin-right: 0px;
    }
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    @include hide-img-icon;
  }
}
</style>
<script>
export default {
  name: "CartInfoCard",
  props: {
    icon: { type: String, required: false },
    imgSrc: { type: String, required: false },
    text: { type: String, required: true },
    infoValue: { type: String, required: true },
    editable: { type: Boolean, default: true },
    isCheckout: { type: Boolean, default: false }
  },
  methods: {
    edit() {
      if (this.editable) {
        this.$emit("edit");
      }
    }
  }
};
</script>
