<template>
  <v-card flat outlined class="cart-info-card h-100 px-6 py-3 px-sm-3 py-sm-3">
    <v-row no-gutters align="center">
      <v-col cols="12" sm="6" lg="12">
        <v-row
          no-gutters
          class="text-body-2 default--text font-weight-bold my-5"
        >
          <v-col cols="6" md="4">
            <span>{{ $t("orders.header.orderNumber") }}</span>
          </v-col>
          <v-col cols="6" md="3">
            <span>{{ $t("orders.header.status") }}</span>
          </v-col>
          <v-col cols="12" md="5"> </v-col>
        </v-row>
        <v-row no-gutters class="text-body-1 font-weight-bold my-5">
          <v-col cols="6" md="4">
            <span class="primary--text">#{{ order.orderId }}</span>
          </v-col>
          <v-col cols="6" md="4">
            <OrderStatusBadge v-bind:order="order" class="default--text" />
          </v-col>
          <v-col cols="12" md="4">
            <OrderQrCode
              v-if="order.orderInfos.PIN_CODE"
              :codeValue="order.orderInfos.PIN_CODE"
              :width="40"
              :height="40"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col class="d-flex flex-wrap" cols="12" sm="6" lg="12">
        <v-btn
          v-if="order.orderStatusId == 8 && order.orderTypeId != 2"
          :href="receiptUrl"
          color="primary"
          class="my-2 mr-2"
          depressed
          @click.prevent="handleReceiptDownload(receiptUrl, order.orderId)"
        >
          <v-icon>$eye</v-icon>
          Visualizza ricevuta
        </v-btn>
        <v-btn
          v-if="order.isEditable"
          @click="editOrder(order.orderId)"
          color="primary"
          class="my-2 mr-2"
          depressed
        >
          <v-icon>$edit</v-icon>
          {{ $t("orders.button.edit") }}
        </v-btn>
        <v-btn
          v-if="order.isPayable"
          @click="showPayment"
          class="my-2 mr-2"
          color="primary"
          depressed
        >
          <v-icon class="pr-2">$creditCard</v-icon>
          {{ $t("orders.button.retryPayment") }}
        </v-btn>
        <v-btn
          color="primary"
          class="my-2 mr-2"
          @click.stop.prevent="addAllToCart(order.orderId)"
          depressed
        >
          <v-icon>$cart</v-icon>
          {{ $t("orders.buttons.reorderOrder") }}
        </v-btn>

        <v-btn @click="printOrder" class="my-2 mr-2" color="primary" depressed>
          <v-icon>$print</v-icon>
          {{ $t("orders.button.print") }}
        </v-btn>
        <v-btn
          v-if="order.isDeletable"
          class="my-2 mr-2"
          color="error"
          @click="deleteOrder(order.orderId)"
          depressed
        >
          <v-icon>$delete</v-icon>
          {{ $t("orders.button.delete") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>
<style>
.icon-credit-card:before {
  content: "\4c";
  color: inherit !important;
}
</style>
<script>
import OrderStatusBadge from "@/components/orders/OrderStatusBadge.vue";
import OrderQrCode from "./OrderQrCode.vue";
import downloadDocumentMixin from "~/mixins/downloadDocument";
export default {
  name: "OrderDetailInfo",
  mixins: [downloadDocumentMixin],
  components: { OrderStatusBadge, OrderQrCode },
  props: ["order"],
  computed: {
    receiptUrl() {
      let orderId = this.order.orderId;
      return `/ebsn/api/order/bill?order_id=${orderId}&type=pdf`;
    }
  },
  methods: {
    addAllToCart(orderId) {
      this.$emit("addAllToCart", {
        orderId
      });
    },
    deleteOrder(orderId) {
      this.$emit("deleteOrder", {
        orderId
      });
    },
    editOrder(orderId) {
      this.$emit("editOrder", {
        orderId
      });
    },
    printOrder() {
      if (this.isCordova) {
        window.cordova.plugins.printer.print();
      } else {
        window.print();
      }
    },
    showPayment() {
      this.$emit("showPayment");
    }
  }
};
</script>
