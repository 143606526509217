<template>
  <v-tooltip top max-width="350">
    <template v-slot:activator="{ on, attrs }">
      <span class="d-flex default--text">
        {{ orderStatus }}
        <v-icon class="ml-2" v-bind="attrs" v-on="on" v-if="!disabled"
          >$info</v-icon
        >
      </span>
    </template>
    <span class="order-status-badge-tooltip-content">{{
      orderStatusTooltip
    }}</span>
  </v-tooltip>
</template>
<style lang="scss"></style>
<script>
export default {
  props: {
    order: { type: Object, required: true },
    disabled: { type: Boolean, default: false }
  },
  computed: {
    orderStatus() {
      return global.vm.$t(
        `order.orderStatusDescr[${this.order.orderStatusId}]`
      );
    },
    orderStatusTooltip() {
      return global.vm.$t(
        `order.orderStatusTooltip[${this.order.orderStatusId}]`
      );
    }
  }
};
</script>
